<template>
	<div class="h100">
		<div class="swiper-box-index">
			<!-- 轮播 -->

			<div class="swiper-index">
				<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination="pagination"
					:navigation="navigation" class="mySwiper">
					<swiper-slide v-for="(item,index) in pageData.banner" :key="index"
						@click="godetails(item)">
						<el-image class="swiper-pic display-block w100 h100" :src="item.pc_image" fit="fit"></el-image>
					</swiper-slide>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</swiper>
			</div>
		</div>

		<div class="minw1200 search-box center">
			<div class="title-item center cure-pointer"
				:class="[i == titleList.length - 1 ? '' : 'paddr26', i == 0 ? '' : 'paddl19 boderl1-E5E5E5']"
				v-for="(item, i) in titleList" :key="i" @click="toPart(item.link)">
				<div class="left flex-column justify-content-between ">
					<span class="fz20 font-blod co-333333">{{item.name}}</span>
					<span class="fz12 co-CFCFCF mart20">{{item.english}}</span>
				</div>
				<div class="right marl34">
					<img class="" :src="require(`../../assets/img/index_0${i + 1}.png`)" alt="" />
				</div>
			</div>
		</div>

		<!-- 列表 -->
		<div class="minw1200 center flex-column">
			<!-- 项目灵感 -->
			<div class="w1200 paddb40">
				<div class="fz34 co-333333 font-blod paddb41 paddt19 relative">
					<span class="l34 display-block">INSPIRATION</span>
					<div class="line absolute left0"></div>
				</div>
				<div class="fz24 co-333333 font-blod paddb41 l24 justify-content-center-between"
					@click="$router.push({path: '/InspirationPart'})">
					<span class="cure-pointer">项目灵感</span>
					<div class="cure-pointer align-items-center">
						<span class="fz16">查看更多</span>
						<img class="index_arrow display-block marl7" src="@/assets/img/index_arrow.png" alt="" />
					</div>
				</div>
				<div class="align-items-center">
					<div style="min-height: 580px;">
						<Inspiration :list="pageData.inspiration"></Inspiration>
					</div>
				</div>
			</div>
			<!-- 课程优选 -->
			<div class="paddb40 bg-F7F7F7 paddt61 center w100">
				<div class="w1200">
					<div class="fz34 co-333333 font-blod paddb41 relative">
						<span class="l34 display-block">OPTIMIZATION</span>
						<div class="line absolute left0"></div>
					</div>
					<div class="fz24 co-333333 font-blod paddb41 l24 justify-content-center-between"
						@click="$router.push({path: '/CourseSelection'})">
						<span class="cure-pointer">课程优选</span>
						<div class="cure-pointer align-items-center">
							<span class="fz16">查看更多</span>
							<img class="index_arrow display-block marl7" src="@/assets/img/index_arrow.png" alt="" />
						</div>
					</div>
					<div class="">
						<div style="min-height: 580px;">
						<ClassSelect :list="pageData.curriculum"></ClassSelect>
						</div>
					</div>
				</div>
			</div>

			<!-- 素材市场 -->
			<div class="w1200 paddb40 paddt61">
				<div class="fz34 co-333333 font-blod paddb41 relative">
					<span class="l34 display-block">MATERIAL</span>
					<div class="line absolute left0"></div>
				</div>
				<div class="fz24 co-333333 font-blod paddb41 l24 justify-content-center-between"
					@click="$router.push({path: '/MaterialMarket'})">
					<span class="cure-pointer">素材市场</span>
					<div class="cure-pointer align-items-center">
						<span class="fz16">查看更多</span>
						<img class="index_arrow display-block marl7" src="@/assets/img/index_arrow.png" alt="" />
					</div>
				</div>
				<div style="min-height: 580px;">
					
					<Material :list="pageData.Materiallist"></Material>
				</div>
			</div>

			<!-- 竞赛信息 -->
			<div class="paddb40 bg-F7F7F7 paddt61 center w100">
				<div class="w1200">
					<div class="fz34 co-333333 font-blod paddb41 relative">
						<span class="l34 display-block">COMPETITION</span>
						<div class="line absolute left0"></div>
					</div>
					<div class="fz24 co-333333 font-blod paddb41 l24 justify-content-center-between"
						@click="$router.push({path: '/CompetitionInfo'})">
						<span class="cure-pointer">竞赛信息</span>
						<div class="cure-pointer align-items-center">
							<span class="fz16">查看更多</span>
							<img class="index_arrow display-block marl7" src="@/assets/img/index_arrow.png" alt="" />
						</div>
					</div>
					<div style="min-height: 580px;">
						<Competition :list='pageData.competitionlist'></Competition>
					</div>
				</div>
			</div>

			<!-- 研图社 -->
			<div class="w1200 paddb40 paddt61">
				<div class="fz34 co-333333 font-blod paddb41 relative">
					<span class="l34 display-block">DRAWING</span>
					<div class="line absolute left0"></div>
				</div>
				<div class="fz24 co-333333 font-blod paddb41 l24 justify-content-center-between"
					@click="$router.push({path: '/YantuSociety'})">
					<span class="cure-pointer">研图社</span>
					<div class="cure-pointer align-items-center">
						<span class="fz16">查看更多</span>
						<img class="index_arrow display-block marl7" src="@/assets/img/index_arrow.png" alt="" />
					</div>
				</div>
				<div style="min-height: 580px;">
					<Drawing :list='pageData.Drawinglist'></Drawing>
				</div>
			</div>		
		</div>
			<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
	// Import Swiper Vue.js components
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	// Import Swiper styles
	import 'swiper/swiper.less';

	import "swiper/components/pagination/pagination.min.css"
	import "swiper/components/navigation/navigation.min.css"
	  import axios from 'axios';
	import SwiperCore, {
		Pagination,
		Navigation,
		Autoplay
	} from 'swiper/core';
	import {
		ref,
		onMounted,
		nextTick,
		getCurrentInstance,
		reactive,
		watchEffect,
	} from 'vue';
	SwiperCore.use([Pagination, Navigation, Autoplay]);

	export default {
		name: "Indexpart",
		components: {
			Swiper,
			SwiperSlide,
		},
		setup() {
			const req1 = getCurrentInstance();
			const router = req1.proxy.$root.$router;
			const request = req1.appContext.config.globalProperties;
			let pageData = reactive({
				banner: [],
				Materiallist: [], //素材市场
				inspiration: [], //项目灵感
				curriculum: [],
				competitionlist: [],
				Drawinglist: []
			});
			let titleList = reactive([{
				name: '找老师',
				link: 'Teacher',
				english: 'teacher'
			}, {
				name: '找机构',
				link: 'Sincerity',
				english: 'institutions'
			}, {
				name: '素材',
				link: 'MaterialMarket',
				english: 'material'
			}, {
				name: '作品',
				link: 'InspirationPart',
				english: 'works'
			}, {
				name: '竞赛',
				link: 'CompetitionInfo',
				english: 'competition'
			}, {
				name: '教程',
				link: 'YantuSociety',
				english: 'The tutorial'
			}, {
				name: '攻略',
				link: 'SearchPage',
				english: 'SearchPage'
			}, ])
			onMounted(() => {

				request.$req({ // 轮播图
					method: 'get',
					url: '/api/maple/carousel',
					data: {
						type: 'index',
						size:12
					},
					success: res => {
						pageData.banner = res || [];

					}
				});
				request.$req({ //素材市场
					method: 'get',
					url: '/api/course',
					data: {
						type: 'source',
						size:12
					},
					success: res => {
						pageData.Materiallist = res || [];
					},
					fail: error => {}
				});
				request.$req({ //项目灵感
					method: 'get',
					url: '/api/article',
					data: {
						type: 'inspiration',
						size:12
					},
					success: res => {
						pageData.inspiration = res || [];
					},
					fail: error => {}
				});
				request.$req({ //课程优选
					method: 'get',
					url: '/api/course',
					data: {
						type: 'course',
						size:12
					},
					success: res => {
						pageData.curriculum = res || [];
					},
					fail: error => {}
				});
				request.$req({ //竞赛
					method: 'get',
					url: '/api/article',
					data: {
						type: 'competition',
						size:12
					},
					success: res => {
						pageData.competitionlist = res || [];
					},
					fail: error => {}
				});
				request.$req({ //研图社
					method: 'get',
					url: '/api/article',
					data: {
						type: 'picture',
						size:12
					},
					success: res => {
						pageData.Drawinglist = res || [];
					},
					fail: error => {}
				});
				
			})
			let toPart = (url, id) => {
				console.log(url, id)
				router.push({
					name: url,
					query: {
						id: id
					}
				});
			}
			return {
				pageData,
				titleList,
				toPart,
				router,
				request
			};
		},
		data() {
			return {
				dialogVisible:false,
				pagination: {
					"clickable": true,
					"bulletActiveClass": "box-01-active",
					"type": 'bullets'
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				size:12,
				Materiallist: [],
				fullscreenLoading: true,
			}
		},
		watch: {
		
		},
		created() {
			// if (this.$store.state.code) {
			// 	let token = localStorage.getItem('token');
			// 	if (!token) {
			// 		let that = this
			// 		this.$req({
			// 			method: 'post',
			// 			url: '/api/wechat/wechatLogin',
			// 			data: {
			// 				code: this.$store.state.code
			// 			},
			// 			success: res => {
			// 				localStorage.setItem('token', res.token);
			// 				localStorage.setItem('timeout', res.expire)
			// 				let token = localStorage.getItem('token');
			// 				that.authorize(token)
			// 			},
			// 			fail: error => {

			// 			}
			// 		});
			// 	} else {
			// 		console.log('有用户信息了')
			// 	}
			// }
			setTimeout(()=>{
				this.fullscreenLoading =false;
			},3000)
		},
		methods: {
			godetails(item) {
				console.log(item)
				if(item.href_type == 1){
					// 跳转外链
					window.location.href = item.web_url
					return
				}
				// 1素材市场 2研图社 3竞赛信息 4院校指南 5项目灵感
				let data = item.link_type;
				let id = item.link_id
				let type = '';
				let url = '/Detail';
				if (data == 'source') {
					type = 1
				} else if (data == 'picture') {
					type = 2
				} else if (data == 'competition') {
					type = 3
				} else if (data == 'school') {
					type = 4
				} else if (data == 'inspiration') {
					type = 5
				}
				else if (data == 'teacher') {
					this.$router.push({
						path: '/TeacherDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				else if (data == 'agency') {
					this.$router.push({
						path: '/SincerityDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				else if (data == 'course') {
					this.$router.push({
						path: '/ClassDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				
				this.$router.push({
					path: url,
					query: {
						type: type,
						id: id
					}
				})
				
			},
			authorize(token) {
				let that = this
				axios({
					url: this.request.$httpUrl + '/api/member',
					method: 'get',
					headers: {
						'Content-Type': 'application/json',
						"Authorization":token,
					},
				}).then(function(res) {
					console.log(res)
					that.$store.commit('setUserInfo', res.data.data);
					that.$message({
						type: 'success',
						message: '授权登入成功'
					});
				}).catch(function(error) {
					console.log(error)
				});
			},
		},
	};
</script>

<style lang="less">
	// 轮播
	.swiper-box-index {
		height: 500px;

		.swiper-index {
			height: 500px;
		}

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;

			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}

		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.swiper-button-next,
		.swiper-button-prev {
			position: absolute;
			top: 50%;
			width: calc(var(--swiper-navigation-size)/ 44 * 27);
			height: var(--swiper-navigation-size);
			margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--swiper-navigation-color, var(--swiper-theme-color));
		}

		.swiper-button-prev {
			width: 36px;
			height: 36px;
			background: url(../../assets/img/prev.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			left: 10px;
			right: auto;
		}

		.swiper-button-next {
			width: 36px;
			height: 36px;
			background: url(../../assets/img/next.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			right: 10px;
			left: auto;
		}

		.swiper-container {
			width: 100%;
			height: 100%;
		}

		.swiper-container {
			margin-left: auto;
			margin-right: auto;
		}

		.img-box {
			background: rgba(0, 0, 0, 0.5);
			transition: all .3s;
			display: none;
		}

		.swiper-button-prev::after {
			content: '';
		}

		.swiper-button-next::after {
			content: '';
		}

		.box-01-active {
			background: #FFFFFF !important;
			opacity: 1 !important;
		}

		.swiper-pagination {
			position: absolute;
			text-align: center;
			transition: .3s opacity;
			transform: translate3d(0, 0, 0);
			z-index: 10;
		}

		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 50%;
			background: #000;
			opacity: .2;
			margin: 0 4px;
		}

		.swiper-pagination-bullet:hover {
			cursor: pointer;
		}

		.swiper-pagination-bullets {
			bottom: 10px;
			left: 0;
			width: 100%;
		}
	}

	// 搜索
	.search-box {
		height: 230px;

		.title-item {
			height: 52px;
		}

		.right {

			img {
				width: 34px;
				height: 34px;
			}
		}
	}

	// 动画过渡
	.animation {
		transition: all 0.3s;
	}

	.line {
		width: 29px;
		height: 2px;
		bottom: 16px;
		background: #DCFF03;
	}

	.index_arrow {
		width: 15px;
		height: 16px;
	}
</style>
